'use strict';
import {createMuiTheme} from '@material-ui/core'
import Box from '@material-ui/core/Box';
import orange from '@material-ui/core/colors/orange'
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import {render} from 'react-dom';
import {hot} from 'react-hot-loader/root';
import {connect, Provider} from 'react-redux';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import {createStore} from 'redux';
import styles from './app.scss';
import {Dashboard} from './components/dashboard';
import main from './reducers';

const theme = createMuiTheme({
    status: {
        danger: orange[500],
    },
    palette: {
        primary: orange
    }
});


let Root = (props) => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Box className={styles.dashboard} maxWidth="lg" height={'100%'}>
                <Provider store={props.store}>
                    <Router>
                        <Switch>
                            <Route exact path='/' component={Dashboard}/>
                            <Route exact path='/:cookbook' component={Dashboard}/>
                            <Route exact path='/:cookbook/:category' component={Dashboard}/>
                            <Route exact path='/:cookbook/:category/:recipe' component={Dashboard}/>
                        </Switch>
                    </Router>
                </Provider>
            </Box>
        </ThemeProvider>
    )
}


let store = createStore(main);

Root = hot(connect((_) => {
    return {}
})(Root));
Root.propTypes = {
    store: PropTypes.object
}
render(
    <Root store={store}/>,
    document.getElementById('dalda')
);

