const initialState = {
    category: null,
};

function main(state = initialState, action) {
    switch (action.type) {
    }
}

export default main;
