import CardActionArea from '@material-ui/core/CardActionArea'
import CardHeader from '@material-ui/core/CardHeader'

import Fade from '@material-ui/core/Fade'
import styles from './dashboard.scss';
import Cookbooks from '../cookbooks';
import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';

function Cookbook(props) {
    return (
        <Fade in={true}>
            <Card className={styles.card} elevation={5}>
                <CardActionArea onClick={() => window.location = `#/${props.slug}`}>
                    <CardHeader title={props.name}/>
                    <CardMedia className={styles.portraitMedia}
                               image={'/' + require(`../cookbooks/${props.cover}`).default}/>
                </CardActionArea>
            </Card>
        </Fade>
    )
}

function CookbookDashboard(props) {
    return (
        <Box className={styles.cookbookDashboard}>
            {Object.entries(props.cookbooks).map(cookbook => {
                return React.createElement(Cookbook, {
                    name: cookbook[1].name,
                    slug: cookbook[0],
                    cover: cookbook[1].cover,
                    key: cookbook[0]
                })
            })}
        </Box>
    )
}

CookbookDashboard.propTypes = {cookbooks: PropTypes.object, match: PropTypes.object}
CookbookDashboard.defaultProps = {
    cookbooks: Cookbooks.list
}
Cookbook.propTypes = {name: PropTypes.string, slug: PropTypes.string, cover: PropTypes.string}

export default CookbookDashboard;
