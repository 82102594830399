import Dalda from './dalda.json'
import {toSlug} from '../util';

export default class Cookbooks {
    static cookbook(cookbookName) {
        switch (cookbookName) {
            case 'dalda':
                return Dalda.reduce(function (initial, value) {
                    let categorySlug = toSlug(value.category);
                    if (!initial.categories[categorySlug]) {
                        initial.categories[categorySlug] = {name: value.category, recipes: {}}
                    }
                    initial.categories[categorySlug].recipes[toSlug(value.name)] = value
                    return initial;
                }, {name: 'Dalda Cookbook: Gold Edition', categories: {}})
            default:
                return {}
        }
    }

    static get list() {
        return {'dalda': {name: 'Dalda Cookbook: Gold Edition', cover: 'assets/dalda-gold-edition.jpg'}}
    }

}