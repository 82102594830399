import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {SentimentVeryDissatisfied} from '@material-ui/icons'
import {Ingredients} from "./ingredients";
import {Instructions} from "./instructions";
import PropTypes from 'prop-types'
import React from "react";
import {RecipeMeta} from './meta'
import styles from "./recipe.scss"
import Cookbooks from "../../cookbooks";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";

function CoverImage({src}) {
    return (
        <div className={styles.coverImage}>
            <img src={`/${src}`}/>
        </div>
    )

}

class Recipe extends React.Component {
    get cookbook() {
        return this.props.cookbook || this.props.match.params.cookbook;
    }

    get category() {
        return this.props.category || this.props.match.params.category;
    }

    get recipe() {
        return this.props.recipe || this.props.match.params.recipe;
    }

    get recipeData() {
        if (!this.recipe) {
            return null
        }
        return Cookbooks.cookbook(this.cookbook).categories[this.category].recipes[this.recipe]
    }

    render() {
        if (this.recipeData) {
            return (
                <>
                    <RecipeMeta recipe={this.recipeData} cookbook={this.cookbook} category={this.category}/>
                    <Card className={styles.recipeCard}>
                        <CardHeader className={styles.recipeName} title={this.recipeData.name}/>
                        <CardContent className={styles.recipeBody}>
                            <div className={styles.recipeLeft}>
                                <CardMedia
                                    className={styles.coverImage}
                                    image={'/' + require(`../../cookbooks/${this.recipeData.image}`).default}
                                />
                                <Ingredients ingredients={this.recipeData.ingredients}/>
                            </div>
                            <Box className={styles.recipeRight} elevation={2}>
                                <Instructions instructions={this.recipeData.instructions}/>
                            </Box>
                        </CardContent>
                    </Card>
                </>
            )
        } else {
            return (
                <>
                    <Box className={styles.recipe404}>
                        <SentimentVeryDissatisfied style={{fontSize: 80}}/>
                        <Typography style={{color: 'black'}}>Recipe not found</Typography>
                    </Box>
                </>
            )

        }
    }
}

CoverImage.propTypes = {
    src: PropTypes.string
}
Recipe.propTypes = {
    cookbook: PropTypes.string,
    category: PropTypes.string,
    recipe: PropTypes.string,
    match: PropTypes.object
};
Recipe.defaultProps = {};

export {Recipe};

