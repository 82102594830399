import AppBar from '@material-ui/core/AppBar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography'
import {EmojiFoodBeverage} from '@material-ui/icons'
import PropTypes from 'prop-types'
import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import Cookbooks from '../cookbooks'
import {toSlug} from '../util';
import CategoryDashboard from './category_collection';
import CookbookDashboard from './cookbook_collection';
import {Recipe} from './recipe';
import RecipeDashboard from './recipe_collection';
import Search from './search';

class Dashboard extends Component {

    get cookbook() {
        return this.props.match.params.cookbook
    }

    get category() {
        return this.props.match.params.category
    }

    onSelected(event, value) {
        if (value) {
            window.location = `#/${this.cookbook}/${toSlug(value.category)}/${toSlug(value.name)}`
        }
    }

    render() {
        return (
            <>
                <AppBar position="static">
                    <Toolbar variant={'dense'}>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={()=>window.location = '/#/'}
                        >
                            <img width='32px' src={'/favicon.ico'}/>
                        </IconButton>
                        <Breadcrumbs aria-label="breadcrumb">
                            {this.props.match.params.cookbook && <Link color="inherit" href={'/#/'}>
                                <Typography>Cookbooks</Typography>
                            </Link>}
                            {this.props.match.params.category && <Link color="inherit" href={`/#/${this.cookbook}/`}>
                                <Typography>{Cookbooks.cookbook(this.cookbook).name}</Typography>
                            </Link>}
                            {this.props.match.params.recipe &&
                            <Link color="inherit" href={`/#/${this.cookbook}/${this.category}`}>
                                <Typography>{Cookbooks.cookbook(this.cookbook).categories[this.category].name}</Typography>
                            </Link>}
                        </Breadcrumbs>
                    </Toolbar>
                </AppBar>
                {this.cookbook &&
                <Search category={this.category} cookbook={this.cookbook} onSelected={this.onSelected.bind(this)}/>}
                <Switch>
                    <Route exact path={'/'} component={CookbookDashboard}/>
                    <Route exact path={'/:cookbook'} component={CategoryDashboard}/>
                    <Route exact path={'/:cookbook/:category'} component={RecipeDashboard}/>
                    <Route path={'/:cookbook/:category/:recipe'} render={(props) => <Recipe {...props}/>}/>
                </Switch>
            </>
        )
    }
}

Dashboard.propTypes = {
    collection: PropTypes.object,
    cookbook: PropTypes.string,
    cookbooks: PropTypes.object,
    category: PropTypes.string,
    recipe: PropTypes.string,
    match: PropTypes.object
}
Dashboard.defaultProps = {
    collection: {}
}
export {Dashboard}
