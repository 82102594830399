import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import * as React from "react"
import PropTypes from "prop-types"
import styles from "./recipe.scss";

function Ingredient({item, quantity, tip}) {
    return (
        <TableRow className={styles.ingredient}>
            <TableCell className={styles.item}>{item}</TableCell>
            <TableCell className={styles.quantity}>
                {quantity}
                {tip && <><br/><small>{tip}</small></>}
            </TableCell>
        </TableRow>
    )
}

function IngredientSet(props) {
    return (
        <TableContainer className={styles.ingredientSet} component={Box}>
            <Table size="small">
                <TableHead className={styles.title}>
                <TableRow>
                    <TableCell colSpan={2}>
                        <Typography variant={'h6'}>{props.name}</Typography>
                    </TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {props.ingredients.map((ingredient, i) => {
                    return React.createElement(Ingredient, {...ingredient, key: i})
                })}
                </TableBody>
            </Table>
        </TableContainer>
    )

}

class Ingredients extends React.Component {
    render() {
        return (
            <Box className={styles.ingredients} elevation={1}>
                {this.props.ingredients.map((ingredientSet, i) => {
                    return React.createElement(
                        IngredientSet, {name: ingredientSet.type, ingredients: ingredientSet.items, key: i}
                    )
                })
                }
            </Box>
        )
    }
}

Ingredient.propTypes = {
    item: PropTypes.string,
    quantity: PropTypes.string
}
Ingredients.propTypes = {
    ingredients: PropTypes.array
}
Ingredients.defaultProps = {
    ingredients: []
}

IngredientSet.propTypes = {
    name: PropTypes.string,
    ingredients: PropTypes.array
}
export {Ingredients}