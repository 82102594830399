import Box from '@material-ui/core/Box'
import {Link} from 'react-router-dom';
import styles from './dashboard.scss';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Cookbooks from '../cookbooks';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from "@material-ui/core/CardMedia";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

function Category(props) {
    return (
        <Card className={styles.card}>
            <CardActionArea onClick={()=>window.location = `${props.link}`}>
                <CardHeader title={props.name}/>
                <CardMedia
                    className={styles.portraitMedia}
                    image={'/' + require(`../cookbooks/${props.recipes[0].image}`).default}
                    title={props.name}
                />
            </CardActionArea>
        </Card>
    )
}

class CategoryDashboard extends Component {
    get cookbook() {
        return this.props.cookbook || this.props.match.params.cookbook
    }

    get categories() {
        return Cookbooks.cookbook(this.cookbook).categories
    }

    render() {
        console.log(this.categories);
        return (
            <Box className={styles.categoryDashboard}>
                {Object.entries(this.categories).map(category => {
                    return React.createElement(Category, {
                        name: category[1].name,
                        recipes: Object.values(category[1].recipes),
                        link: `#/${this.cookbook}/${category[0]}`,
                        key: category[0]
                    })
                })}
            </Box>
        )
    }
}

CategoryDashboard.propTypes = {cookbook: PropTypes.string, match: PropTypes.object}
Category.propTypes = {
    name: PropTypes.string,
    cookbook: PropTypes.string,
    recipes: PropTypes.array,
    link: PropTypes.string
}

export default CategoryDashboard;
