import Box from '@material-ui/core/Box'
// eslint-disable-next-line no-unused-vars
import Typography from '@material-ui/core/Typography'
import * as React from "react";
import PropTypes from "prop-types"
import styles from "./recipe.scss"

function InstructionStep(props) {
    return (
        <li className={styles.step}>
            <Typography>{props.step}</Typography>
        </li>
    )
}

function InstructionSet(props) {
    return (
        <Box className={styles.instructionSet}>
            <Typography className={styles.title} variant={'h5'}>{props.name}</Typography>
            {props.steps.length > 1 ? (
                    <ol>
                        {props.steps.map((step, i) => {
                            return React.createElement(InstructionStep, {step: step, key: i})
                        })}
                    </ol>
                )
                :
                props.steps[0]
            }
        </Box>
    )
}


class Instructions extends React.Component {
    render() {
        return (
            <div className={styles.instructions}>
                {this.props.instructions.map((instructionSet, i) => {
                    return React.createElement(InstructionSet, {
                        name: instructionSet.type,
                        steps: instructionSet.steps,
                        key: i
                    })
                })}
            </div>
        )
    }
}

InstructionStep.propTypes = {
    instruction: PropTypes.string
};
Instructions.propTypes = {
    instructions: PropTypes.array
};
Instructions.defaultProps = {
    instructions: []
};
InstructionSet.propTypes = {
    name: PropTypes.string,
    steps: PropTypes.array
}
export {Instructions}
