import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import matchSorter from 'match-sorter';
import PropTypes from 'prop-types'
import React from 'react';
import Cookbooks from '../cookbooks';
import * as styles from './search.scss'

class Search extends React.Component {

    get suggestions() {
        return Object.entries(Cookbooks.cookbook(this.props.cookbook).categories).reduce((initial, entry) => {
            if(!this.props.category || entry[0] === this.props.category){
                initial = initial.concat(Object.values(entry[1].recipes))
            }
            return initial
        }, []);
    }

    filterOptions = (options, {inputValue}) => {
        return matchSorter(options, inputValue, {keys: ['name']});
    }

    render() {
        console.log(this.props)
        return (
            <Autocomplete
                id="cookbook-search"
                options={this.suggestions}
                groupBy={(option) => option.category}
                getOptionLabel={(option) => option.name}
                className={styles.autoComplete}
                filterOptions={this.filterOptions}
                onChange={this.props.onSelected}
                renderInput={(params) => <TextField {...params} label="Search Recipies" variant="outlined"/>}

            />
        )
    }
}

Search.propTypes = {
    onSelected: PropTypes.func,
    cookbook: PropTypes.string,
    category: PropTypes.string,
}
export default Search