import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import {Link} from 'react-router-dom';
import styles from './dashboard.scss';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {toSlug} from "../util";
import Cookbooks from "../cookbooks";

function RecipeCard(props) {
    return (
        <Card className={styles.card}>
            <CardActionArea onClick={()=>window.location = `${props.link}`}>
                <CardHeader title={props.recipe.name}/>
                <CardMedia
                    className={styles.portraitMedia}
                    image={'/' + require(`../cookbooks/${props.recipe.image}`).default}
                    title={props.name}
                />
            </CardActionArea>
        </Card>
    )
}

class RecipeDashboard extends Component {
    get cookbook() {
        return this.props.cookbook || this.props.match.params.cookbook
    }

    get category() {
        return this.props.category || this.props.match.params.category
    }

    get recipes() {
        return Cookbooks.cookbook(this.cookbook).categories[this.category].recipes
    }

    render() {
        return (
            <Box className={styles.recipeDashboard}>
                {Object.entries(this.recipes).map(recipe => {
                    return React.createElement(RecipeCard, {
                        recipe: recipe[1],
                        link: `#/${this.cookbook}/${this.category}/${recipe[0]}`,
                        key: recipe[0]
                    })
                })}
            </Box>
        )
    }
}

RecipeDashboard.propTypes = {
    category: PropTypes.string, recipes: PropTypes.array,
    cookbook: PropTypes.string, match: PropTypes.object
}
RecipeCard.propTypes = {recipe: PropTypes.object, link: PropTypes.string}

export default RecipeDashboard;
