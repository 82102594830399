import PropType from 'prop-types'
import React from 'react';
import {Helmet} from 'react-helmet/es/Helmet'
import {JsonLd} from 'react-schemaorg';
import {toSlug} from '../../util'

function RecipeMeta({cookbook, category, recipe}) {
    console.log(recipe)
    return (
        <>
            <JsonLd item={
                {
                    '@context': 'https://schema.org/',
                    '@type': 'Recipe',
                    'name': recipe.name,
                    'image': [
                        SITE_ROOT + require('../../cookbooks/' + recipe.image).default,
                    ],
                    'author': {
                        '@type': 'Organization',
                        'name': recipe.source || 'Dalda'
                    },
                    'datePublished': '2020-04-01',
                    'recipeCategory': recipe.category,
                    'recipeCuisine': 'Pakistani',
                    'recipeIngredient': recipe.ingredients[0].items.map(ingredient => `${ingredient.item} - ${ingredient.quantity}`),
                    'recipeInstructions': recipe.instructions[0].steps.map(step => {
                        return {'@type': 'HowToStep', 'text': step}
                    }),
                }
            }/>
            <Helmet
                title={recipe.name}
                meta={[
                {name: 'author', content: "Dalda"},
                {property: 'og:title', content: recipe.name},
                {property: 'og:site_name', content: "eat@algor.ist"},
                {property: 'og:type', content: "website"},
                {property: 'og:url', content: `${SITE_ROOT}/${cookbook}/${category}/${toSlug(recipe.name)}`},
                {property: 'og:image', content: `${SITE_ROOT}/${require('../../cookbooks/' + recipe.image).default}`},
                {name: 'viewport', content: 'width=device-width, maximum-scale=1'},
                ]}
            />
        </>
    )
}

RecipeMeta.propTypes = {
    cookbook: PropType.string,
    category: PropType.string,
    recipe: PropType.object,
}
export {RecipeMeta}
